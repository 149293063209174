import React from 'react'
import { Link } from 'react-scroll'

const Hero = () => (
    <section id='hero' name='hero'>
        <div className='text-container'>
            <h1>Jonas Olaussen</h1>
            <h2>An IT-Management consultant with development experience</h2>
            <Link to='about' className='button light' smooth={true}>
                Tell me more
            </Link>
        </div>
        <div className='wave-container'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
                <path
                    fill='#ffffff'
                    fill-opacity='1'
                    d='M0,256L48,250.7C96,245,192,235,288,234.7C384,235,480,245,576,234.7C672,224,768,192,864,192C960,192,1056,224,1152,240C1248,256,1344,256,1392,256L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
                ></path>
            </svg>
        </div>
    </section>
)

export default Hero
