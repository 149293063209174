import React from 'react'
import ResumeItem from '../Components/ResumeItem'
import FilterButton from '../Components/FilterButton'
import { resumeDataRaw } from '../data/ResumeData'

class Resume extends React.Component {
    state = {
        firstFilterPress: true,
        allAvailableFilters: [],
        resumeData: [],
        curFilters: [],
    }

    componentDidMount = () => {
        this.setState({ resumeData: resumeDataRaw })
        this.buildAndSetFilters()
    }

    render = () => (
        <section id='resume' className='section'>
            <div className='container'>
                <h2>This is what I have done</h2>
                <div className='filters'>
                    <span>Filter on my former roles </span>
                    {this.state.allAvailableFilters &&
                        this.state.allAvailableFilters.map((role) => (
                            <FilterButton
                                text={role}
                                clickHandler={this.filterHandler}
                                curFilters={this.state.curFilters}
                            />
                        ))}
                </div>
                <div className='experiences'>
                    {this.state.resumeData &&
                        this.state.resumeData.map((resumeItem) => (
                            <ResumeItem resumeItem={resumeItem} />
                        ))}
                </div>
            </div>
        </section>
    )

    addFilter = (filterToAdd) => {
        const newFilters = this.state.curFilters
        newFilters.indexOf(filterToAdd) === -1 && newFilters.push(filterToAdd)
        this.setState(
            {
                curFilters: newFilters,
            },
            () => this.filterResumeData(),
            this.state.allAvailableFilters.length ===
                this.state.curFilters.length &&
                this.setState({ firstFilterPress: true })
        )
    }

    buildAndSetFilters = () => {
        const newFilters = []
        resumeDataRaw.map((resumeItem) =>
            resumeItem.roleType.map(
                (roleType) =>
                    newFilters.indexOf(roleType) === -1 &&
                    newFilters.push(roleType)
            )
        )
        this.setState({
            allAvailableFilters: [...newFilters],
            curFilters: newFilters,
        })
    }

    filterHandler = (curFilter) => {
        this.state.firstFilterPress
            ? this.setState(
                  { curFilters: [curFilter], firstFilterPress: false },
                  () => this.filterResumeData()
              )
            : this.state.curFilters.indexOf(curFilter) === -1
            ? this.addFilter(curFilter)
            : this.removeFilter(curFilter)
    }

    filterResumeData = () => {
        const curFilter = this.state.curFilters
        const newResumeData = []
        resumeDataRaw.map((resumeItem) =>
            resumeItem.roleType.map(
                (roleType) =>
                    curFilter.indexOf(roleType) > -1 &&
                    newResumeData.push(resumeItem)
            )
        )
        this.setState({
            resumeData: newResumeData,
        })
    }

    removeFilter = (filterToRemove) => {
        const newFilters = this.state.curFilters
        newFilters.splice(newFilters.indexOf(filterToRemove), 1)
        this.setState(
            {
                curFilters: newFilters,
            },
            () => this.filterResumeData()
        )
    }
}

export default Resume
