import React, { useState } from 'react'
import Button from './Button'

const ResumeItem = (props) => {
    const [open, toggleState] = useState(false)
    return (
        <div className={`resume-item ${open && 'open'}`}>
            <header>
                {props.resumeItem.img && (
                    <img
                        src={require(`../img/references/${props.resumeItem.img}`)}
                    />
                )}
            </header>
            <div className='body'>
                <h3>{props.resumeItem.title}</h3>
                <h4>{props.resumeItem.company}</h4>
                <h4>{props.resumeItem.period}</h4>
                <p>{props.resumeItem.aboutClient}</p>
                {open && (
                    <>
                        <p>{props.resumeItem.problemStatement}</p>
                        <p>{props.resumeItem.myRole}</p>
                        {props.resumeItem.readMoreLink && (
                            <a
                                href={`${props.resumeItem.readMoreLink}`}
                                target='_blank'
                            >
                                Read more
                            </a>
                        )}
                    </>
                )}
                <footer onClick={() => toggleState(!open)}>
                    <div>
                        {props.resumeItem.roleType.map((role) => (
                            <span>{role}</span>
                        ))}
                    </div>
                    <Button text={open ? 'Read less' : 'Read more'} />
                </footer>
            </div>
        </div>
    )
}

export default ResumeItem
