export const resumeDataRaw = [
    {
        img: 'Nepa.png',
        title: 'Offering development',
        company: 'Nepa',
        period: '2020 Q1 - 2020 Q4',
        aboutClient:
            'Nepa helps clients with data analytics surrounding customer experience and marketing. Their services include brand tracking, customer experience, path to purchase, marketing mix modelling and innovation acceleration.',
        problemStatement:
            'Nepa experienced problems with their core business model due to a lacking client offering. They were seeking advice surrounding in what possibilities they could extend their business model in order to capture larger revenues from both new and current clients. Nepa was undergoing pressure from its competitors, leading them to seek advice surrounding how to transform or extend their value offering.',
        myRole:
            "Jonas conducted an extensive company- and market analysis to be able to recommend the most suitable next step for Nepa. Utilizing his analytical and business skills he delivered a multiscenario solution involving both internal and external stakeholders. This resulted in a concrete roadmap for Nepa's long term strategy work. Transforming Nepa's current offer to an advisory based offer leading to a more competitive client offer and increased potential revenues.",
        roleType: ['Business Analyst'],
    },
    {
        img: 'SoccerQvizz.png',
        title: 'Marketing website',
        company: 'SoccerQvizZ',
        period: '2019 Q4 - 2020 Q1',
        aboutClient:
            'SoccerQvizZ is a Swedish startup developing a soccer quiz application. The team behind the application consistently develops the content creating a more fulfilling experience for their users.',
        problemStatement:
            'When the application was near release the SoccerQvizz team needed a comprehensive market plan. There was a clear need for a strategy in order to achieve a successful launch. Due to the lack of inhouse competence and resources, the SoccerQvizz team was in need for guidance in how to create a successful market release.',
        myRole:
            'Jonas managed a team consisting of both marketers and developers outlining the entire roadmap for SoccerQvizz launch. As a project manager, he and his team created a marketing campaign including several components such as a successful landing page to attract attention to the application. Jonas was very much appreciated for his ability to understand SoccerQvizz business and translate it into a concrete action plan. The launch of the application was greatly thanks to Jonas very successful and later led to a large increase in the number of new users of            the application.',
        roleType: ['Project Manager', 'Full Stack Developer'],
        readMoreLink: 'http://soccerqvizz.com/',
    },
    {
        img: 'Teltek.png',
        title: 'Market Optimized Website',
        company: 'Teltek AB',
        period: '2019 Q4 - 2020 Q1',
        aboutClient:
            'Teltek is a Swedish producer of industrial weights, used for controlling products in various industries such as pharmaceutical, food and manufacturing. They are the leading Nordic provider of these types of products with a known reputation for quality and world-class service.',
        problemStatement:
            'Teltek was looking to expand their business both in Sweden and internationally and needed help with developing a new website optimized for their target audience. Teltek’s aim with the new website was to grow their revenue by increasing conversion rate and rank high on search engines, with the help of content- and user flow optimization.',
        myRole:
            'As a product owner, Jonas was in control of the entire development process from requirements gathering to delivery. Jonas took great pride in understanding Teltek’s core business offering to be able to guide his team in the right direction. By applying an iterative approach, he ensured that the client always was able to give valuable input on the current product and next steps. Jonas was apricated for his ability to quickly get an understanding of Teltek’s business and translating that into a successful website. After the launch of the new optimized website, Teltek increased their page views and conversion rate both domestically and internationally.',
        roleType: ['Product Owner'],
        readMoreLink: 'http://teltek.se/',
    },
    {
        img: 'DF.png',
        title: 'Process Optimization',
        company: 'Digital Framgång',
        period: '2019 Q3 - 2020 Q2',
        aboutClient:
            'Digital Framgång delivers SEO and conversion-optimized web pages to clients. The main target audience for the company is small and medium-sized companies. By analyzing the client in combination with selected market research Digital Framgång delivers websites that highlight the client’s strengths',
        problemStatement:
            'The company grew rapidly and needed a competent project manager to develop their internal processes. At the beginning of the project, there were problems with bridging the gap between the business and development units. This gap created confusion and inefficiency in processes both internally and in client projects leading to double work and loss in profits.',
        myRole:
            'Jonas utilized his experience regarding development projects and processes to create an optimal workflow for Digital Framgång. To combat the current problems Jonas implemented a tailored agile process with the basis in Scrum to match Digital Framgång’s current workflow and requirements. This full fledge process includes tasks such as requirements gathering, establishments of project plans, development processes as well as time and resources plans. These improvements led to higher transparency, increased efficiency and less double work resulting in increased profits.',
        roleType: ['Project Manager'],
        readMoreLink: 'http://digitlframgang.com/',
    },
    {
        img: 'Accenture.png',
        title: 'Big Data Analyst',
        company: 'Telecom Client (Accenture)',
        period: '2019 Q2 - 2019 Q3',
        aboutClient:
            'The Telecom Client is one of the leading telecom providers in the Nordics delivering solutions for data communication, internet, digital-tv, and mobile communication.',
        problemStatement:
            'The state of the company’s big data lake was unstructured making it confusing to work with for data analysts, developers and key stakeholders due to factors such as up-to-datedness and ambiguity. This situation made simple tasks hard and unclear which led to a lot of trapped potentials. There was a need for solving this problem to make the client able to provide better offerings and services to their customers, creating a long-lasting competitive advantage.',
        myRole:
            'By analyzing the current state of the client’s big data lake Jonas identified and executed improvements, such as                ensuring the right data sources were used and that sufficient metadata was available, creating a better experience for his team and client. Furthermore, these improvements generated higher trust in the data leading to greater reliability in decision making. Jonas also worked with generating insights based on the data regarding customer behaviour and created visualization in Qlik Sense for the client’s business side. Jonas work resulted in significant time savings for all internal stakeholders creating more efficient and reliable operations.',
        roleType: ['Business Analyst'],
        readMoreLink: 'http://accenture.se/',
    },
    {
        img: 'NordicAirline.png',
        title: 'In-flight information',
        company: 'Nordic Airline',
        period: '2019 Q1 - 2019 Q2',
        aboutClient:
            'The International airline is one of the Nordic leading airlines with both domestic and international traffic. The airline takes pride in applying a modern approach and great customer service.',
        problemStatement:
            "The Nordic airline had an old application installed on a stationary unit in the aircraft's cockpits for all in-flight information i.e. weather, routes, time to destination etc. Due to the stationary nature of the unit, it was difficult for all member of the crew to gain access to this valuable information. Therefore, the airline was looking for a solution that could make this vital information more accessible.",
        myRole:
            "Jonas was appointed product owner of this project due to his former experience in developing successful applications. As the product owner, he first gained a comprehensive understanding of the current application and the airline's needs. Jonas and his team then developed a modern progressive web application in React.JS integrated with the airlines current APIs. During the entire development process, key stakeholders were involved to ensure that the end product met their needs. This made it possible for pilots and stewardesses to gain access to the information via phones and tablets leading to more efficient knowledge transfer. The new solution resulted in quicker communications to travellers leading to higher customer service.",
        roleType: ['Product Owner'],
    },
    {
        img: 'WKIT.png',
        title: 'Process Optimization',
        company: 'We Know IT',
        period: '2019 Q1 - 2019 Q3',
        aboutClient:
            'We Know IT is an in-house consulting company that develops mobile apps, web apps and custom-made IT systems for customers all over Sweden. The company is spread across offices in Stockholm, Göteborg, Lund, and Linköping.',
        problemStatement:
            "We Know IT had trouble with their internal processes leading to losses in revenues and profits. Their main problem was that the management control process was undefined and lacked important data. These problems led to troubles with We Know IT's control and follow-up process, as well as affecting the decision process leading to non-optimal business decisions.",
        myRole:
            'As business analyst Jonas improved the decision-making process of We Know IT’s management by transforming the company to make decisions based on data rather than on hunches. To generate this data, Jonas established new routines and a new management control system. Jonas was also part of the transformation part of the project, educating and following up on the use of these new processes, improving and developing them further based on feedback. His initiatives and business development efforts resulted in increased year-over-year revenue and profit in all four regions. Jonas was appreciated for his efficiency, structure and drive to always improve the business.',
        roleType: ['Business Analyst'],
        readMoreLink: 'http://weknowit.se/',
    },
    {
        img: 'MotalaKommun.png',
        title: 'Employee Survey Application',
        company: 'Municipality of Motala',
        period: '2018 Q3 - 2019 Q1',
        aboutClient:
            'Motala is a medium-sized municipality in Sweden with an ambition to be modern and have high employee satisfaction. They have an active online presence and a drive to introduce new digital solutions into their daily working environment.',
        problemStatement:
            'Motala had for a long time conducted employee surveys with traditional methods such as paper copies. This method resulted in a low response rate and unnecessary manual work. In parallel, the municipality had ambitions to create a more interactive employee feedback tool. Therefore, Motala was searching for a new and modern tool to improve its employee survey process.',
        myRole:
            "As the product owner and project coordinator, Jonas was responsible for the entire development process and the development team. By utilizing his customer interaction skills, he was able to quickly understand the client needs and propose a suitable solution. As the product owner Jonas and his team developed an application with an appealing UI and UX aiming to increase the response rate. A few months after the launch, the application had increased the response rate several times and saved significant working hours for the team analyzing the employee surveys. Jonas was very appreciated for his communication skills and his ability to translate the client's vision into a real product.",
        roleType: ['Product Owner'],
    },
    {
        img: 'WKIT.png',
        title: 'Regional Director',
        company: 'We Know IT',
        period: '2018 Q2 - 2019 Q1',
        aboutClient:
            'We Know IT is an in-house consulting company that develops mobile apps, web apps and custom-made IT systems for customers all over Sweden. The company is spread across offices in Stockholm, Göteborg, Lund, and Linköping.',
        problemStatement:
            "When Jonas started as Project Manager at We Know IT, there were problems in the customer acquisition process leading to very low revenues. Furthermore, the company had large overhead costs leading to negative profits. The consultant's morale was low and several of them had just left the company.",
        myRole:
            'By utilizing his analytical skills, Jonas created a new system for matching consultant’s competences with incoming assignments as well as an evaluation tool to be used when choosing clients and future projects. At the same time, he recruited 10 new consultants to his region, making it the largest region in the company and focused on the social aspects of work. These improvements led to a 400% increase in sales in his region, setting a new sales record in the company’s history. Furthermore, he focused on creating a valued working environment resulting in a high performing and motivated team. This enabled him to manage several development projects, coordinate clients’ needs and his group of 10 consultants.',
        roleType: ['Project Manager'],
        readMoreLink: 'http://weknowit.se/',
    },
    {
        img: 'WKIT.png',
        title: 'Full Stack Developer',
        company: 'We Know IT',
        period: '2017 Q3 - 2018 Q2',
        aboutClient:
            'We Know IT is an in-house consulting company that develops mobile apps, web apps and custom-made IT systems for customers all over Sweden. The company is spread across offices in Stockholm, Göteborg, Lund, and Linköping.',
        problemStatement:
            'As a Web Developer for We Know IT, Jonas worked with several clients and internal projects envisioning and designing technical aspects to ensure alignment with the client’s goals. He both singlehandedly and as part of a team developed web-pages and web-applications.',
        myRole:
            'Jonas took great responsibility and quickly got to handle all client interaction and focused on value creation. During his time at We Know IT he become proficient in several frameworks and tools, such as React, Firebase, Django etc. Furthermore, Jonas coached and interacted with other consultants in and outside of Linköping, creating a better working environment and exchanging knowledge.',
        roleType: ['Full Stack Developer'],
        readMoreLink: 'http://weknowit.se/',
    },
    {
        img: 'Citypuls.png',
        title: 'Full Stack Developer',
        company: 'City Puls',
        period: '2013 Q2 - 2015 Q3',
        aboutClient:
            'Citypuls started as a small web development company that delivered apps and web applications to their clients. The founders then released Biljettkiosken, a cloud-based ticketing system with smaller events and nightclubs as the target audience.',
        problemStatement:
            'Citypuls expanded its business from an ordinary web development company to delivering a ticketing service. Therefore, they had a clear need for a new full stack developer and project manager supporting the former business. ',
        myRole:
            'Jonas worked as a full stack developer and project manager, working with various projects and clients. He was solely responsible for the entire client delivery working with requirements gathering, design and development, and project management.',
        roleType: ['Full Stack Developer'],
        readMoreLink: 'http://citypuls.se/',
    },
]
