import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const Contact = () => (
    <section id='contact'>
        <div className='wave-container'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
                <path
                    fill='#cfdee7'
                    fill-opacity='1'
                    d='M0,256L48,250.7C96,245,192,235,288,234.7C384,235,480,245,576,234.7C672,224,768,192,864,192C960,192,1056,224,1152,240C1248,256,1344,256,1392,256L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
                ></path>
            </svg>
        </div>
        <div className='container'>
            <h2>How to reach me</h2>
            <p>
                I am always interested in new ideas, partnerships or projects.
                Feel free to reach out to me!
            </p>
            <span>
                <FontAwesomeIcon icon={faEnvelope} />
                <a href='mailto:info@jonasolaussen.se'>info@jonasolaussen.se</a>
            </span>
            <span>
                <FontAwesomeIcon icon={faPhone} />
                <a href='tel:0046738969209'>+46 73 896 92 09</a>
            </span>
            <span>
                Or read more about me on{' '}
                <a
                    href='https://www.linkedin.com/in/jonas-olaussen/'
                    target='_blank'
                >
                    Linked In
                </a>
            </span>
        </div>
    </section>
)

export default Contact
