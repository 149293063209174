import React, { useState } from 'react'

const FilterButton = (props) => {
    const [active, toggleActive] = useState(true)
    return (
        <button
            className={`filter ${
                props.curFilters.indexOf(props.text) > -1 && 'active'
            }`}
            onClick={() => {
                props.clickHandler(props.text)
                toggleActive(!active)
            }}
        >
            {props.text}
        </button>
    )
}

export default FilterButton
