import React from 'react'
import './style/main.scss'
import Hero from './Sections/Hero'
import About from './Sections/About'
import Resume from './Sections/Resume'
import Contact from './Sections/Contact'

const App = () => (
    <>
        <Hero />
        <About />
        <Resume />
        <Contact />
    </>
)

export default App
