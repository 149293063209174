import React from 'react'
import profileImage from '../img/JonasOlaussen.jpg'

const skillsLanguages = ['PHP', 'JavaScript', 'Python', 'Java', 'HTML', 'CSS']
const skillsFrameworksAndPlatforms = [
    'React',
    'jQuery',
    'WordPress',
    'SCSS',
    'Git',
    'Node.js',
    'Firebase',
    'Google Cloud',
]
const skillsMethodsAndSoftSkills = [
    'Business Analysis',
    'IT-Advisory',
    'Product Ownership',
    'Project Management',
    'Requirements Analysis',
    'Agile',
    'Scrum',
]

const aboutText =
    'I am a disciplined and consistent Project Manager and Full Stack Developer with various experience from several development projects. My strengths lie in the combination of deep technical knowledge combined with business development experiences, enabling successful project management from both technical and business aspects. These skills have been developed during several years of first-hand development experiences both as a consultant, project manager and as part of a company’s management in various industries. The possibility to develop solutions to business needs and problems with the help of technology is one of my main drivers. From my former experience, I have gained a holistic mindset of implementation processes empowering me to always stay on top of things and looking out for every stakeholder’s interest.'

const About = () => (
    <section id='about' className='section'>
        <div className='container'>
            <div className='col-1-2'>
                <h2>Hi, my name is Jonas!</h2>
                <p>{aboutText}</p>
                <h3>These are my skills</h3>
                <h4>Programming Languages</h4>
                <div className='skills'>
                    {skillsLanguages.map((skill) => (
                        <span className='skill'>{skill}</span>
                    ))}
                </div>
                <h4>Frameworks & Platforms</h4>
                <div className='skills'>
                    {skillsFrameworksAndPlatforms.map((skill) => (
                        <span className='skill'>{skill}</span>
                    ))}
                </div>
                <h4>Methodology and Soft Skills</h4>
                <div className='skills'>
                    {skillsMethodsAndSoftSkills.map((skill) => (
                        <span className='skill'>{skill}</span>
                    ))}
                </div>
            </div>
            <div className='col-2-2'>
                <img src={profileImage} alt='Jonas Olaussen portrait' />
            </div>
        </div>
    </section>
)

export default About
